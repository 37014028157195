import Repository from './repository'
import LoginParam from '@/models/auth/login-param'

const KEY_CODE_VERIFIER = 'code_verifier'
const KEY_STATE = 'state'

export default class LoginParamRepository extends Repository {
  /**
   * ログインパラメータをローカルストレージから取得する
   * @return {LoginParam} ログインパラメータ
   */
  static get() {
    return new LoginParam(super.get(KEY_CODE_VERIFIER), super.get(KEY_STATE))
  }

  /**
   * ログインパラメータをローカルストレージに設定する
   * @param {LoginParam} loginParam ログインパラメータ
   */
  static set(loginParam) {
    super.set(KEY_CODE_VERIFIER, loginParam.codeVerifier)
    super.set(KEY_STATE, loginParam.state)
  }

  /**
   * ログインパラメータをローカルストレージから削除する
   */
  static remove() {
    super.remove(KEY_CODE_VERIFIER)
    super.remove(KEY_STATE)
  }
}
