import flightRepository from './flight-repository'
import imgRepository from './img-repository'
import missionRepository from './mission-repository'
import pilotRepository from './pilot-repository'
import authRepository from './auth-repository'
import deviceRepository from './device-repository'
import liveviewRepository from './liveview-repository'
import companyRepository from './company-repository'
import companyAdminRepository from './company-admin-repository'

export default {
  auth: authRepository,
  device: deviceRepository,
  flight: flightRepository,
  img: imgRepository,
  mission: missionRepository,
  pilot: pilotRepository,
  liveview: liveviewRepository,
  company: companyRepository,
  companyAdmin: companyAdminRepository,
}
