import camera from './ja/camera'
import companies from './ja/companies'
import contents from './ja/contents'
import device from './ja/device'
import menu from './ja/menu'
import mission from './ja/mission'
import users from './ja/users'
import user from './ja/user'
import common from './ja/common'
import exif from './ja/exif'

export default {
  message: {
    camera,
    companies,
    contents,
    device,
    menu,
    mission,
    users,
    user,
    common,
    exif,
  },
}
