export default {
  'list-title': 'デバイス一覧',
  'create-title': 'デバイス作成',
  'edit-title': 'デバイス編集/削除',
  'detail-title': 'デバイス詳細',
  'camera-title': 'カメラ設定',
  'create-button': '作成',
  'updata-button': '編集',
  'delete-button': '削除',
  'device': {
    'device-name': 'デバイス名',
    'uuid': 'UUID',
    'date': '登録日',
    'online-info': 'オンライン情報',
  },
  'status': {
    'offline': 'オフライン',
    'online-camera-on': 'オンライン＋カメラ接続有',
    'online-camera-off': 'オンライン＋カメラ接続無',
    'unknown': '不明',
  },
}
