import repository from "@/repositories/repository";
import { deleteAllBrowserData, setLoginParam, getLoginParam, removeLoginParam, setAuthenticateInfo, getAuthenticateInfo } from "@/repositories/browser-data/repository";
import pkceChallenge, { generateChallenge } from "pkce-challenge";
import { v4 as uuidv4 } from "uuid";
import { BASE_URL, setUserMe, toAuthenticateInfoModel } from "./interceptors/auth";
import LoginParam from "@/models/auth/login-param";

const REDIRECT_URI = process.env.VUE_APP_REDIRECT_URI;

export default {
  getLoginURL() {
    deleteAllBrowserData();

    const { code_verifier } = pkceChallenge();
    const state = uuidv4();
    const loginParam = new LoginParam(code_verifier, state)
    setLoginParam(loginParam);

    const params = {
      'redirect_uri': REDIRECT_URI,
      'lang': 'en', // TODO: set ui lang
      'country': 'US', // TODO: set ui country
      'state': state ?? '',
      'code_challenge': generateChallenge(code_verifier ?? ''),
      'code_challenge_method': 'S256',
    }

    repository
      .get(`${BASE_URL}/auth`, {params})
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.body.location;
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {});
  },
  changePassword() {
    const params = {
      'lang': 'en', // TODO: set ui lang
    }

    repository
      .get(`${BASE_URL}/accountSetting`, {params})
      .then(function (response) {
        // 200OKで返ることはなく、正常の場合は302リダイレクトで返ってくる想定のため、ここに入ることはない
        // 現在は、200OKの中に302リダイレクトのステータスコードが含まれている形で返ってくる
        window.location.href = response.data.body.location;
      })
      .catch(function (error) {
        if (error.status === 302) {
          window.location.href = error.response.data.body.location;
        } else {
          console.error(error);
        }
      })
      .finally(function () {});
  },
  exchangeCode(code, state, callback) {
    const loginParam = getLoginParam();
    if (state != loginParam.state) {
      console.error("state is invalid");
      return;
    }
    const code_verifier = loginParam.codeVerifier;
    repository
      .post(`${BASE_URL}/token`, {
        redirect_uri: REDIRECT_URI,
        code_verifier: code_verifier,
        code: code,
      })
      .then(function (response) {
        removeLoginParam();
        setAuthenticateInfo(toAuthenticateInfoModel(response));
        if (callback) callback();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {});
  },
  async getUserMeInfo() {
    try {
      const response = await repository.get(`${BASE_URL}/user/me`);
      return setUserMe(response);
    } catch(error) {
      return Promise.reject(error);
    }
  },
  postLogout(callback) {
    const authenticateInfo = getAuthenticateInfo();
    const logoutRequest = {
      access_token: authenticateInfo.accessToken,
    };
    repository
      .post(`${BASE_URL}/token:revoke`, logoutRequest)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        deleteAllBrowserData();
        callback();
      });
  },
};
