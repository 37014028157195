export default class LiveviewInfo {
  constructor(props) {
    this.TenantId = props.TenantId
    this.CompanyId = props.CompanyId
    this.UserId = props.UserId
    this.DeviceUuid = props.DeviceUuid
    this.ModelName = props.ModelName
    this.RegistrationDate = props.RegistrationDate
    this.DeviceNickname = props.DeviceNickname
    this.Region = props.Region
  }
}
