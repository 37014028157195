export default {
  'user-title': 'User Information',
  'password-title': 'Update password',
  'updata-title': 'Edit User Information',
  'updata-button': 'Edit',
  'password-button': 'Update password',
  'user-info': {
    'user-name': 'User name',
    'mail': 'Mail address',
    'authority': 'Authority',
    'bank': 'Bank',
  },
  'password': {
    'current': 'Current password',
    'new': 'New password',
    'confirmation': 'Confirm new password',
  },
}
