export default {
  'user-title': 'ユーザー情報',
  'password-title': 'パスワード更新',
  'updata-title': 'ユーザー情報編集',
  'updata-button': '編集',
  'password-button': 'パスワード更新',
  'user-info': {
    'user-name': 'ユーザー名',
    'mail': 'メールアドレス',
    'authority': '権限',
    'bank': '口座',
  },
  'password': {
    'current': '現在のパスワード',
    'new': '新パスワード',
    'confirmation': '新パスワード確認',
  },
}
