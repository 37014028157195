import Repository from './repository'
import UserInfo from '@/models/auth/user-info'

const KEY_TENANT_ID = 'tenant_id'
const KEY_COMPANY_ID = 'company_id'
const KEY_USER_ID = 'user_id'
const KEY_ROLE = 'role'

export default class UserInfoRepository extends Repository {
  /**
   * ユーザー情報をローカルストレージから取得する
   * @return {UserInfo} ユーザー情報
   */
  static get() {
    return new UserInfo(super.get(KEY_TENANT_ID), super.get(KEY_COMPANY_ID), super.get(KEY_USER_ID), super.get(KEY_ROLE))
  }

  /**
   * ユーザー情報をローカルストレージに設定する
   * @param {UserInfo} userInfo ユーザー情報
   */
  static set(userInfo) {
    super.set(KEY_TENANT_ID, userInfo.tenantId)
    super.set(KEY_COMPANY_ID, userInfo.companyId)
    super.set(KEY_USER_ID, userInfo.userId)
    super.set(KEY_ROLE, userInfo.role)
  }

  /**
   * ユーザー情報をローカルストレージから削除する
   */
  static remove() {
    super.remove(KEY_TENANT_ID)
    super.remove(KEY_COMPANY_ID)
    super.remove(KEY_USER_ID)
    super.remove(KEY_ROLE)
  }
}
