export default {
  'site-title': 'Drone Data Cloud',
  'headline': 'Menu',
  'home': 'Home',
  'login': 'Login',
  'mission-list': 'Mission list',
  'password-update': 'Account settings',
  'device-list': 'Device list',
  'drone-user': 'Drone pilot list',
  'company-list': 'Company list',
  'open-source-license': 'open-source license',
  'logout': 'Logout',
  'confirm-header': 'confirmation',
  'confirm-logout': 'Do you want to logout?',
  'ok': 'OK',
  'cancel': 'Cancel',
}
