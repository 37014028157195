export default class AuthInfo {
  constructor(props) {
    this.userId = props.userId
    this.userName = props.userName
    this.tenantId = props.tenantId
    this.companyId = props.companyId
    this.role = props.role
    this.s3Bucket = props.s3Bucket
    this.websocketEndpoints = props.websocketEndpoints
  }
}
