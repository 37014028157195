import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import lazyload from './plugins/vue-lazyload'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

createApp(App).use(router).use(i18n).use(lazyload).mount('#app')
