export default class PilotInfo {
  constructor(props) {
    this.pilotId = props.pilotId
    this.activateStatus = props.activateStatus
    this.created = props.created
    this.firstName = props.firstName
    this.lastName = props.lastName
    this.middleName = props.middleName
    this.email = props.email
    this.multiFactorAuth = props.multiFactorAuth
    this.role = props.role
  }
}
