export default {
  'list-title': 'Mission list',
  'create-title': 'Mission create',
  'edit-title': 'Mission edit',
  'detail-title': 'Mission details',
  'create-button': 'Create',
  'updata-button': 'Edit',
  'hidden-button': 'Hidden',
  'redisplay-button': 'Redisplay',
  'cancel-button': 'Cancel',
  'selection-button': 'Selection',
  'all-missions': 'All missions',
  'my-missions': 'My missions',
  'hidden-mission': 'Hidden mission',
  'form': {
    'placeholder': {
      'mission-name': 'Mission Name',
      'mission-date': 'Implementation Date',
      'location-lat': 'longitude',
      'location-lng': 'latitude',
      'now-data': 'Now',
    },
    'select-text': 'Selecting the ...',
  },
  'error-message': {
    'select-pilot': 'Please select a pilot',
    'enter-mission-name': 'Please enter a mission name',
    'enter-implementation-date': 'Please enter the implementation date',
    'create-update-failed': 'Create/update failed',
  },
  'index': {
    'caption': 'Latest mission list',
    'header': {
      'mission-title': 'Mission name',
      'mission-date': 'Implementation date',
      'mission-pilot': 'Pilot',
      'status': 'Status',
    },
  },
  'list': {
    'caption': 'Mission list',
    'header': {
      'mission-title': 'Mission name',
      'mission-date': 'Date of implementation',
      'mission-pilot': 'Pilot',
      'status': 'Status',
    },
  },
  'detail': {
    'mission-name': 'Mission name',
    'mission-date': 'Implementation date',
    'pilot-name': 'Pilot name',
    'location': 'Location',
    'location-lat': 'Longitude',
    'location-lng': 'Latitude',
    'status': 'Status',
    'flight-info': {
      'flight-title': 'Flight information',
      'timestamp': 'Time stamping',
      'Location': {
        'Location': 'location information',
        'gps': 'GPS',
        'rtk': 'RTK',
      },
      'direction': 'direction',
      'networkType': 'Network Type',
      'flightStatus': 'Flight Status',
      'rfStrength': 'RF Strength',
      'cellInfo': 'cell information',
      'flightTime': 'Flight time',
      'Battery': {
        'Battery': 'Battery',
        'drone': 'Drone',
        'camera': 'Camera',
        'android': 'Android',
      },
      'beacon': 'beacon',
      'applicationStatus': 'Application Status',
      'android': {
        'android': 'Android',
        'SysTemp': 'Systems Temp',
        'FanOutput': 'Fan output',
      },
      'DiskSpace': {
        'DiskSpace': 'Disk space',
        'camera': 'Camera',
        'android': 'Android',
      },
      'statusCode': 'status code',
    },
    'img-info': {
      'img-title': 'Photo',
      'modal-close': 'Close',
      'thumbnail-img': 'Thumbnail image of',
      'original-img': 'Original image',
    },
    'live-view': {
      'live-view-title': 'Live view',
      'live-view-start-button': 'Start streaming',
      'live-view-stop-button': 'Stop streaming',
    },
  },
  'status': {
    'planned': 'Planned',
    'ongoing': 'Ongoing',
    'finished': 'Finished',
  },
}
