export default class AuthenticateInfo {
  /**
   * コンストラクタ
   * @param {string} accessToken アクセストークン
   * @param {string} refreshToken リフレッシュトークン
   * @param {number} expiresIn 有効期限
   */
  constructor(accessToken, refreshToken, expiresIn) {
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.expiresIn = expiresIn
  }
 
  isAccessToken() {
    if (this.accessToken) {
      return true
    } else {
      return false
    }
  }
  isLogin() {
    if (this.accessToken && this.refreshToken) {
      return true
    } else {
      return false
    }
  }
  isNotLogin() {
    if (this.accessToken && this.refreshToken) {
      return false
    } else {
      return true
    }
  }
}
