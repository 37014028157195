export default {
  'list-title-drone': 'ドローンパイロットユーザー一覧',
  'create-title-drone': 'ドローンパイロットユーザー作成',
  'updata-title-drone': 'ドローンパイロットユーザー編集',
  'create-button': '作成',
  'updata-button': '編集',
  'delete-button': '削除',
  'cancel-button': 'キャンセル',
  'detail': {
    'first-name': '名',
    'last-name': '姓',
    'middle-name': 'ミドルネーム',
  },
  'user-info': {
    'name': '名前',
    'email': 'メール',
    'multi-factor-auth': '認証',
    'multi-factor-auth-true': '有効',
    'multi-factor-auth-false': '無効',
    'created': '作成日',
    'activate-status': 'アクティベートステータス',
  },
  'error-message': {
    'enter-first-name': '名を入力してください',
    'incorrect-first-name': '名前が正しく入力されていません',
    'enter-last-name': '姓を入力してください',
    'incorrect-last-name': '姓が正しく入力されていません',
    'enter-email': '有効なメールアドレスを入力してください',
    'email-in-use': 'このメールアドレスは既に使用されています',
    'select-mfa': '多要素認証を選択してください',
    'create-update-failed': '作成・更新に失敗しました',
    'auth-failed': '認証に失敗しました。',
    'not-access': '利用中のユーザーアカウントには、アクセス権限がありません。',
    'invalid-data': '無効なデータのため取得に失敗しました。',
    'get-failed': 'データの取得に失敗しました。',
  },
  'select-users': {
    'company': 'カンパニー',
    'company-admin': 'カンパニー管理者',
    'select-company': '%{subject}を選択してください',
  },
}
