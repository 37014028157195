import FlightStatus from '@/models/flight-status'
import repository from '@/repositories/repository'
import { getUserInfo } from './browser-data/repository'

const basePath = () => {
  return `/api/v1/tenants/${getUserInfo().tenantId}/companies/${getUserInfo().companyId}/missions`
}

const toModelFlightStatus = ({
  tenant_id,
  company_id,
  user_id,
  device_uuid,
  mission_id,
  timestamp,
  gps_location,
  rtk_location,
  direction,
  network_type,
  flight_status,
  rf_strength,
  cell_info,
  flight_time,
  drone_battery,
  camera_battery,
  android_battery,
  beacon,
  application_status,
  android_sys_temp,
  android_fan_output,
  camera_disk_space,
  android_disk_space,
  status_code,
}) => {
  return new FlightStatus({
    tenantId: tenant_id,
    companyId: company_id,
    userId: user_id,
    deviceUuid: device_uuid,
    missionId: mission_id,
    timestamp: timestamp,
    gpsLocation: gps_location,
    rtkLocation: rtk_location,
    direction: direction,
    networkType: network_type,
    flightStatus: flight_status,
    rfStrength: rf_strength,
    cellInfo: cell_info,
    flightTime: flight_time,
    droneBattery: drone_battery,
    cameraBattery: camera_battery,
    androidBattery: android_battery,
    beacon: beacon,
    applicationStatus: application_status,
    androidSysTemp: android_sys_temp,
    androidFanOutput: android_fan_output,
    cameraDiskSpace: camera_disk_space,
    androidDiskSpace: android_disk_space,
    statusCode: status_code,
  })
}

export default {
  getFlightStatus(missionId, callback) {
    return repository.get(`${basePath()}/${missionId}/status`)
      .then(function (response) {
        let responseDataBody = response.data.body

        // レスポンスがnullの場合は空として扱う
        if (!responseDataBody) {
          responseDataBody = {}
        }

        const flightStatus = toModelFlightStatus(responseDataBody)
        callback(flightStatus)
        return flightStatus
      })
      .catch(function (error) {
        // エラーやレスポンスがnullの場合はエラーログを出して、空の情報を返す
        console.error(error)
        callback(toModelFlightStatus({}))
      })
      .finally(function () {})
  },
}
