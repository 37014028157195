export default class Repository {
  /**
   * ローカルストレージから値を取得する
   * @param {string} key キー名
   * @return {string} 値
   */
  static get(key) {
    return localStorage.getItem(key)
  }

  /**
   * ローカルストレージへ値を入れる
   * @param {string} key キー名
   * @param {string} value 値
   */
  static set(key, value) {
    return localStorage.setItem(key, value)
  }

  /**
   * ローカルストレージからキー項目を削除する
   * @param {string} key キー名
   */
  static remove(key) {
    return localStorage.removeItem(key)
  }
}
