import PilotInfo from '@/models/pilot-info'
import repository from '@/repositories/repository'
import { getUserInfo } from './browser-data/repository'

const tenantId = () => {
  return getUserInfo().tenantId
}
const companyId = () => {
  return getUserInfo().companyId
}
const userId = () => {
  return getUserInfo().userId
}
const basePath = `/api/v1`

const toModelPilotInfo = ({
  id,
  status,
  created,
  first_name,
  last_name,
  middle_name,
  email,
  multi_factor_auth,
  role,
}) => {
  return new PilotInfo({
    pilotId: id,
    activateStatus: status,
    created: created,
    firstName: first_name,
    lastName: last_name,
    middleName: middle_name,
    email: email,
    multiFactorAuth: multi_factor_auth,
    role: role,
  })
}

const toModelPilotInfos = (pilotInfos) => {
  let modelPilotInfos = []

  pilotInfos.forEach( function(pilotInfo) {
    modelPilotInfos.push(toModelPilotInfo(pilotInfo))
  })

  return modelPilotInfos
}

export default {
  getPilotInfos(companyUserData, callback) {
    let userData = {
      companyId: companyId(),
      userId: userId(),
    }
    if (companyUserData.companyId != null) {
      userData.companyId = companyUserData.companyId
      userData.userId = companyUserData.companyAdminId
    }
    repository.get(`${basePath}/pilots?tenant_id=${tenantId()}&company_id=${userData.companyId}&user_id=${userData.userId}`)
      .then(function (response) {
        callback(toModelPilotInfos(response.data.body))
      })
      .catch(function (error) {
        callback(error.response)
        console.error(error)
      })
      .finally(function () {})
  },
  createPilotInfo(companyUserData, pilotInfo) {
    let userData = {
      companyId: companyId(),
      userId: userId(),
    }
    if (companyUserData.companyId != null) {
      userData.companyId = companyUserData.companyId
      userData.pilotId = companyUserData.companyAdminId
    }
    if (pilotInfo instanceof PilotInfo) {
      const createPilotInfoRequest = {
        first_name: pilotInfo.firstName,
        last_name: pilotInfo.lastName,
        middle_name: pilotInfo.middleName,
        email: pilotInfo.email,
        multi_factor_auth: pilotInfo.multiFactorAuth,
      }

      return repository.post(`${basePath}/pilot?tenant_id=${tenantId()}&company_id=${userData.companyId}&user_id=${userData.userId}`, createPilotInfoRequest)
    }
  },
  deletePilotInfo() {
    // TODO: Implement API for deletion
    return console.log('Delete Pilot')
  },
  updatePilotInfo() {
    // TODO: Implement API for update
    return console.log('update Pilot')
  }
}
