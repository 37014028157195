import repositoryFactory from "@/repositories/repository-factory"

export const loginUser = () => {
  repositoryFactory.auth.getLoginURL()
}

export const changePassword = (redirectUri) => {
  repositoryFactory.auth.changePassword(redirectUri)
}

export const exchangeCode = (code, state, callback) => {
  return repositoryFactory.auth.exchangeCode(code, state, callback)
}

export const getUserMeInfo = async () => {
  return await repositoryFactory.auth.getUserMeInfo()
}

export const postLogout = (callback) => {
  return repositoryFactory.auth.postLogout(callback)
}
