import DeviceInfo from '@/models/device-info'
import DeviceStatus from '@/models/device-status'
import repository from '@/repositories/repository'
import { getUserInfo } from './browser-data/repository'

const basePath = () => {
  return `/api/v1/tenants/${getUserInfo().tenantId}/companies/${getUserInfo().companyId}/users/${getUserInfo().userId}/devices`
}

const toModelDeviceList = (deviceList) => {
  let deviceInfos = []

  if ( Array.isArray(deviceList) ) {
    deviceList.forEach( function(deviceInfo) {
      deviceInfos.push(toModelDeviceInfo(deviceInfo))
    })
  }

  return deviceInfos
}

const toModelDeviceInfo = ({
  device_uuid,
  device_nickname,
  registration_date,
}) => {
  return new DeviceInfo({
    deviceId: device_uuid,
    deviceName: device_nickname,
    registrationDate: registration_date,
  })
}

const toModelDeviceStatus = (deviceStatus) => {
  return new DeviceStatus({
    applicationStatus: deviceStatus,
  })
}

export default {
  getDeviceList(callback) {
    repository.get(`${basePath()}`)
      .then(function (response) {
        callback(toModelDeviceList(response.data.body.list))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  getDeviceStatus(deviceId, callback) {
    repository.get(`${basePath()}/${deviceId}/status`)
      .then(function (response) {
        callback(toModelDeviceStatus(response.data.body.status))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  getDeviceInfo(deviceId, callback) {
    repository.get(`${basePath()}/${deviceId}`)
      .then(function (response) {
        callback(toModelDeviceInfo(response.data.body))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
}
