import { getUserInfo } from '@/repositories/browser-data/repository'

/**
 * テナントユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isTenantRoleView = (to, from, next) => {
  if (getUserInfo().isTenant()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}

/**
 * ドローンユーザーの以外場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isNotDroneRoleView = (to, from, next) => {
  if (getUserInfo().isNotDrone()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}

/**
 * ドローンユーザーまたはカンパニーユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isDroneOrCompanyRoleView = (to, from, next) => {
  if (getUserInfo().isDrone() || getUserInfo().isCompany()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}

/**
 * カンパニーユーザーまたはテナントユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isCompanyOrTenantRoleView = (to, from, next) => {
  if (getUserInfo().isCompany() || getUserInfo().isTenant()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}
