import AuthInfo from '@/models/auth-info'
import { getAuthenticateInfo } from '@/repositories/browser-data/repository'
import { getUserMeInfo } from '@/services/auth'

let authInfo = new AuthInfo({})

/**
 * 認証が必要なページである。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @return {boolean} true:認証が必要なページ、false:認証が不要なページ
 */
const isAuthenticationRequiredPage = (to) => {
  // ログインページではないページ且つ、コールバックページではないページは、認証が必要なページ
  if (to.name !== 'LoginView' && to.name !== 'Callback') {
    return true
  } else {
    return false
  }
}

/**
 * 認証情報をブラウザデータにセットして次へ、認証情報を取得できない場合はログインページへ
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
const setAuthenticationAndNext = async (to, next) => {
  try {
    authInfo = await getUserMeInfo()
    next()
  } catch {
    next({ name: 'LoginView' })
  }
}

/**
 * 認証情報をユーザー情報として返却
 */
export const userMeInfo = () => {
  return {
    authInfo: authInfo,
  }
}

/**
 * テナントユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const authenticate = (to, from, next) => {
  if ( isAuthenticationRequiredPage(to) && getAuthenticateInfo().isNotLogin()) {
    // 認証が必要なページ且つ、未ログインの場合はログインページへ遷移
    next({ name: 'LoginView' })
  } else if (!isAuthenticationRequiredPage(to) && getAuthenticateInfo().isLogin()) {
    // 認証が不要なページ（ログインページ以外は認証必須の想定）且つ、ログイン済みの場合はトップページに遷移
    next({ name: 'HomeView' })
  } else if (isAuthenticationRequiredPage(to)) {
    // 認証が必要なページの場合、認証情報をブラウザデータにセットして次へ
    setAuthenticationAndNext(to, next)
  } else {
    // それ以外の場合はそのまま次へ
    next()
  }
}
