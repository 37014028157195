import Repository from './repository'
import AuthenticateInfo from '@/models/auth/authenticate-info'

const KEY_ACCESS_TOKEN = 'access_token'
const KEY_REFRESH_TOKEN = 'refresh_token'
const KEY_EXPIRES_IN = 'expires_in'

export default class AuthenticateInfoRepository extends Repository {
  /**
   * 認証情報をローカルストレージから取得する
   * @return {AuthenticateInfo} 認証情報
   */
  static get() {
    return new AuthenticateInfo(super.get(KEY_ACCESS_TOKEN), super.get(KEY_REFRESH_TOKEN), super.get(KEY_EXPIRES_IN))
  }

  /**
   * 認証情報をローカルストレージに設定する
   * @param {AuthenticateInfo} authenticateInfo 認証情報
   */
  static set(authenticateInfo) {
    super.set(KEY_ACCESS_TOKEN, authenticateInfo.accessToken)
    super.set(KEY_REFRESH_TOKEN, authenticateInfo.refreshToken)
    super.set(KEY_EXPIRES_IN, authenticateInfo.expiresIn)
  }

  /**
   * 認証情報をローカルストレージから削除する
   */
  static remove() {
    super.remove(KEY_ACCESS_TOKEN)
    super.remove(KEY_REFRESH_TOKEN)
    super.remove(KEY_EXPIRES_IN)
  }
}
