export default class FlightStatus {
  constructor(props) {
    this.tenantId = props.tenantId
    this.companyId = props.companyId
    this.userId = props.userId
    this.deviceUuid = props.deviceUuid
    this.missionId = props.missionId
    this.timestamp = props.timestamp
    this.gpsLocation = props.gpsLocation
    this.rtkLocation = props.rtkLocation
    this.direction = props.direction
    this.networkType = props.networkType
    this.flightStatus = props.flightStatus
    this.rfStrength = props.rf_strength
    this.cellInfo = props.cell_info
    this.flightTime = props.flightTime
    this.droneBattery = props.droneBattery
    this.cameraBattery = props.cameraBattery
    this.androidBattery = props.androidBattery
    this.beacon = props.beacon
    this.applicationStatus = props.applicationStatus
    this.androidSysTemp = props.androidSysTemp
    this.androidFanOutput = props.androidFanOutput
    this.cameraDiskSpace = props.cameraDiskSpace
    this.androidDiskSpace = props.androidDiskSpace
    this.statusCode = props.statusCode
  }
}
