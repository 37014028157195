import LoginParam from '@/models/auth/login-param'
import LoginParamRepository from './local-storage/login-param-repository'
import AuthenticateInfo from '@/models/auth/authenticate-info'
import AuthenticateInfoRepository from './local-storage/authenticate-info-repository'
import UserInfo from '@/models/auth/user-info'
import UserInfoRepository from './local-storage/user-info-repository'

export const deleteAllBrowserData = () => {
  localStorage.clear()
}

/**
 * ログインパラメータをブラウザから取得する
 * @return {LoginParam} ログインパラメータ
 */
export const getLoginParam = () => {
  return LoginParamRepository.get()
}

/**
 * ログインパラメータをブラウザに設定する
 * @param {LoginParam} loginParam ログインパラメータ
 */
export const setLoginParam = (loginParam) => {
  if (loginParam instanceof LoginParam) {
    return LoginParamRepository.set(loginParam)
  } else {
    throw new Error("param error")
  }
}

/**
 * ログインパラメータをブラウザから削除する
 */
export const removeLoginParam = () => {
  return LoginParamRepository.remove()
}

/**
 * 認証情報をブラウザから取得する
 */
export const getAuthenticateInfo = () => {
  return AuthenticateInfoRepository.get()
}

/**
 * 認証情報をブラウザに設定する
 * @param {AuthenticateInfo} authenticateInfo 認証情報
 */
export const setAuthenticateInfo = (authenticateInfo) => {
  if (authenticateInfo instanceof AuthenticateInfo) {
    return AuthenticateInfoRepository.set(authenticateInfo)
  } else {
    throw new Error("param error")
  }
}

/**
 * 認証情報をブラウザから削除する
 */
export const removeAuthenticateInfo = () => {
  return AuthenticateInfoRepository.remove()
}

/**
 * ユーザー情報をブラウザから取得する
 */
export const getUserInfo = () => {
  return UserInfoRepository.get()
}

/**
 * ユーザー情報をブラウザに設定する
 * @param {UserInfo} userInfo ユーザー情報
 */
export const setUserInfo = (userInfo) => {
  if (userInfo instanceof UserInfo) {
    return UserInfoRepository.set(userInfo)
  } else {
    throw new Error("param error")
  }
}

/**
 * ユーザー情報をブラウザから削除する
 */
export const removeUserInfo = () => {
  return UserInfoRepository.remove()
}
