export default class CompanyInfo {
  constructor(props) {
    this.parentId = props.parentId
    this.id = props.id
    this.companyName = props.companyName
    this.streetAddress = props.streetAddress
    this.city = props.city
    this.state = props.state
    this.countryCode = props.countryCode
    this.zip = props.zip
  }
}
