export default {
  'exif-title': 'Image metadata',
  'exif': 'Exif',
  'tiff': 'TIFF',
  'pixel-xdimension': 'PixelXDimension',
  'pixel-ydimension': 'PixelYDimension',
  'file-source': 'FileSource',
  'scene-type': 'SceneType',
  'custom-rendered': 'CustomRendered',
  'color-space': 'ColorSpace',
  'components-configuration': 'ComponentsConfiguration',
  'compressed-bitsperpixel': 'CompressedBitsPerPixel',
  'f-number': 'FNumber',
  'brightness-value': 'BrightnessValue',
  'exposure-time': 'ExposureTime',
  'exposure-mode': 'ExposureMode',
  'iso-speed-ratings': 'ISOSpeedRatings',
  'exposure-bias': 'ExposureBias',
  'focal-length': 'FocalLength',
  'max-aperture-value': 'MaxApertureValue',
  'metering-mode': 'MeteringMode',
  'flash': 'Flash',
  'flashpix-version': 'FlashpixVersion',
  'focal-length-in-35mm-film': 'FocalLengthIn35mmFilm',
  'scene-capture-type': 'SceneCaptureType',
  'contrast': 'Contrast',
  'light-source': 'LightSource',
  'exposure-program': 'ExposureProgram',
  'saturation': 'Saturation',
  'sharpness': 'Sharpness',
  'white-balance': 'WhiteBalance',
  'digital-zoom-ration': 'DigitalZoomRation',
  'exif-ifo-pointer': 'ExifIFDPointer',
  'exif-version': 'ExifVersion',
  'make': 'Make',
  'model': 'Model',
  'x-resolution': 'XResolution',
  'y-resolution': 'YResolution',
  'resolution-unit': 'ResolutionUnit',
  'ycbcr-positioning': 'YCbCrPositioning',
}
