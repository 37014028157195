export default {
  'exif-title': '画像のメタデータ',
  'exif': 'Exif',
  'tiff': 'TIFF',
  'pixel-xdimension': '実効画像幅',
  'pixel-ydimension': '実効画像高さ',
  'file-source': 'ファイルソース',
  'scene-type': 'シーンタイプ',
  'custom-rendered': '個別画像処理',
  'color-space': '色空間情報',
  'components-configuration': '各コンポーネントの意味',
  'compressed-bitsperpixel': '画像圧縮モード',
  'f-number': 'Fナンバー',
  'brightness-value': '輝度値',
  'exposure-time': '露出時間',
  'exposure-mode': '露出モード',
  'iso-speed-ratings': 'ISOスピード',
  'exposure-bias': '露光補正値',
  'focal-length': '焦点距離',
  'max-aperture-value': '最大絞り値',
  'metering-mode': '測光方式',
  'flash': 'フラッシュ',
  'flashpix-version': '対応フラッシュピックスバージョン',
  'focal-length-in-35mm-film': '35mm焦点距離',
  'scene-capture-type': '撮影シーンタイプ',
  'contrast': '撮影コントラスト',
  'light-source': '光源',
  'exposure-program': '露出プログラム',
  'saturation': '撮影彩度',
  'sharpness': '撮影シャープネス',
  'white-balance': 'ホワイトバランス',
  'digital-zoom-ration': 'デジタルズーム倍率',
  'exif-ifo-pointer': 'Exifタグ',
  'exif-version': 'Exifバージョン',
  'make': 'カメラの製造元',
  'model': 'カメラのモデル',
  'x-resolution': '水平方向の解像度',
  'y-resolution': '垂直方向の解像度',
  'resolution-unit': '解像度の単位',
  'ycbcr-positioning': 'YCCの画素構成(YとCの位置)',
}
