import CompanyInfo from '@/models/company-info'
import repository from '@/repositories/repository'
import { getUserInfo } from './browser-data/repository'

const tenantId = () => {
  return getUserInfo().tenantId
}
const userId = () => {
  return getUserInfo().userId
}
const basePath = `/api/v1`

const toModelCompanyInfo = ({
  parentId,
  id,
  company_name,
  street_address,
  city,
  state,
  zip,
  country_code,
}) => {
  return new CompanyInfo({
    parentId: parentId,
    id: id,
    companyName: company_name,
    streetAddress: street_address,
    city: city,
    state: state,
    zip: zip,
    countryCode: country_code,
  })
}

const toModelCompanyInfos = (companyInfos) => {
  let modelCompanyInfos = []

  companyInfos.forEach( function(companyInfo) {
    modelCompanyInfos.push(toModelCompanyInfo(companyInfo))
  })

  return modelCompanyInfos
}

export default {
  getCompanyInfos(callback) {
    repository
      .get(`${basePath}/companies?tenant_id=${tenantId()}&user_id=${userId()}`)
      .then(function (response) {
        callback(toModelCompanyInfos(response.data.body))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  createCompanyInfo(companyInfo) {
    if (companyInfo instanceof CompanyInfo) {
      const createCompanyInfoRequest = {
        user_id: userId(),
        company_name: companyInfo.companyName,
        street_address: companyInfo.streetAddress,
        city: companyInfo.city,
        state: companyInfo.state,
        country_code: companyInfo.countryCode,
        zip: companyInfo.zip,
      }

      return repository.post(`${basePath}/company?tenant_id=${tenantId()}`, createCompanyInfoRequest)
    }
  },
  updateCompanyInfo(companyId, companyInfo) {
    if (companyInfo instanceof CompanyInfo) {
      const updateCompanyInfoRequest = {
        user_id: userId(),
        company_name: companyInfo.companyName,
        street_address: companyInfo.streetAddress,
        city: companyInfo.city,
        state: companyInfo.state,
        country_code: companyInfo.countryCode,
        zip: companyInfo.zip,
      }

      return repository.put(`${basePath}/company?tenant_id=${tenantId()}&company_id=${companyId}`, updateCompanyInfoRequest)
    }
  },
}
