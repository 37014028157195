export default class UserInfo {
  /**
   * コンストラクタ
   * @param {string} tenantId テナントID
   * @param {string} companyId カンパニーID
   * @param {string} userId ユーザーID
   * @param {string} role ロール
   */
  constructor(tenantId, companyId, userId, role = '') {
    this.tenantId = tenantId
    this.companyId = companyId
    this.userId = userId
    this.role = role
  }

  isDrone() {
    if (this.role === '') {
      return true
    } else {
      return false
    }
  }
  isNotDrone() {
    if (this.role === '') {
      return false
    } else {
      return true
    }
  }
  isCompany() {
    if (this.role === '1') {
      return true
    } else {
      return false
    }
  }
  isTenant() {
    if (this.role === '0') {
      return true
    } else {
      return false
    }
  }
}
