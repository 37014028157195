import CompanyAdminInfo from '@/models/company-admin-info'
import repository from '@/repositories/repository'

const basePath = `/api/v1/company`

const toModelCompanyAdminInfo = (apiResponseBody) => {
  return new CompanyAdminInfo(
    apiResponseBody.id
    , apiResponseBody.status
    , apiResponseBody.created
    , apiResponseBody.first_name
    , apiResponseBody.last_name
    , apiResponseBody.middle_name
    , apiResponseBody.email
    , apiResponseBody.multi_factor_auth
    , apiResponseBody.role
  )
}

const toModelCompanyAdminInfos = (companyAdminInfos) => {
  let modelCompanyAdminInfos = []

  companyAdminInfos.forEach( function(companyAdminInfo) {
    modelCompanyAdminInfos.push(toModelCompanyAdminInfo(companyAdminInfo))
  })

  return modelCompanyAdminInfos
}

export default {
  /**
   * 指定カンパニーのカンパニーアドミン一覧取得
   * @param {string} designatedCompanyId 指定カンパニーID
   * @param {import('@/models/auth/user-info').default} userInfo 自ユーザー情報
   * @param {*} callback コールバック関数
   */
  getCompanyAdminInfos(designatedCompanyId, userInfo, callback) {
    return repository
      .get(`${basePath}/admins?tenant_id=${userInfo.tenantId}&company_id=${designatedCompanyId}&user_id=${userInfo.userId}`)
      .then(function (response) {
        callback(toModelCompanyAdminInfos(response.data.body))
      })
      .catch(function (error) {
        if (error.response.status === 403 || error.response.status === 400) {
          console.error(error)
          window.location.href = '/'
        } else {
          console.error(error)
        }
      })
      .finally(function () {})
  },
  /**
   * 指定カンパニーのカンパニーアドミン作成
   * @param {string} designatedCompanyId 指定カンパニーID
   * @param {import('@/models/auth/user-info').default} userInfo 自ユーザー情報
   * @param {CompanyAdminInfo} companyAdminInfo カンパニーアドミン情報
   */
  createCompanyAdminInfo(designatedCompanyId, userInfo, companyAdminInfo) {
    if (companyAdminInfo instanceof CompanyAdminInfo) {
      const createCompanyAdminInfoRequest = {
        first_name: companyAdminInfo.firstName,
        last_name: companyAdminInfo.lastName,
        middle_name: companyAdminInfo.middleName,
        email: companyAdminInfo.email,
        multi_factor_auth: companyAdminInfo.multiFactorAuth,
      }
      return repository.post(`${basePath}/admin?tenant_id=${userInfo.tenantId}&company_id=${designatedCompanyId}&user_id=${userInfo.userId}`, createCompanyAdminInfoRequest)
    }
  },
  deleteCompanyAdminInfo() {
    // TODO: Implement API for deletion
    return console.log('Delete CompanyAdmin')
  },
  updateCompanyAdminInfo() {
    // TODO: Implement API for update
    return console.log('Update CompanyAdmin')
  },
}
