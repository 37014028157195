export default {
  'list-title': 'Company list',
  'create-title': 'Company create',
  'edit-title': 'Company edit',
  'list-admin-title': 'Company administrator list',
  'create-admin-title': 'Company administrator create',
  'updata-admin-title': 'Company administrator edit',
  'create-button': 'Create',
  'updata-button': 'Edit',
  'delete-button': 'Delete',
  'cancel-button': 'Cancel',
  'companies': {
    'company-name': 'Company name',
    'address': 'Address',
    'city': 'City',
    'state': 'State/Province/Region',
    'country': 'Country/Region',
    'zip': 'Zip/Postal code',
    'date': 'Registration date',
  },
  'error-message': {
    'enter-company-name': 'Please enter your company name',
    'company-name-in-use': 'This company name is already in use',
    'enter-address': 'Please enter your address',
    'enter-city': 'Please enter city',
    'enter-state': 'Please enter your state',
    'enter-country': 'Please enter your country/region',
    'enter-zip': 'Please enter your zip code',
    'create-update-failed': 'Create/update failed',
  },
}
