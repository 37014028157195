import ImgInfo from '@/models/img-info'
import repository from '@/repositories/repository'
import { getUserInfo } from './browser-data/repository'

const basePath = () => {
  return `/api/v1/tenants/${getUserInfo().tenantId}/companies/${getUserInfo().companyId}/missions`
}

const toModelImgInfo = ({
  image_id,
  image,
}) => {
  return new ImgInfo({
    imgId: image_id,
    imgSrc: image
  })
}

const toModelImgInfos = (imgfos) => {
  let modelImgInfos = []

  if (imgfos != null) {
    imgfos.forEach( function(ImgInfo) {
      modelImgInfos.push(toModelImgInfo(ImgInfo))
    })
  }

  return modelImgInfos
}

export default {
  getImgInfo(missionId, imgId, callback) {
    const resultImgId = encodeURIComponent(imgId)
    repository.get(`${basePath()}/${missionId}/images/${resultImgId}/original_image`)
      .then(function (response) {
        callback(response.data.body)
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  /**
   * サムネイル画像情報取得
   * @param {string} missionId 対応するミッションID
   * @param {number} startIndex サムネイルリスト中の取得開始番号
   * @return {Promise<ImgInfo>} 指定したサムネイル画像情報
   */
  async getThumbnailImageInfo(missionId, startIndex) {
    const params = {
      'start_index': startIndex,
      'count': 1, // 指定されたインデックス番号のサムネイル画像情報のみ取得のため1固定
    }

    return repository.get(`${basePath()}/${missionId}/thumbnail_images`, {params})
      .then(function (response) {
        const thumbnailImageInfo = toModelImgInfos(response.data.body)[0]
        if (thumbnailImageInfo) {
          return Promise.resolve(thumbnailImageInfo)
        } else {
          // データが取得できなかった場合、エラーとして返す。
          return Promise.reject(new Error('No Data'))
        }
      })
      .catch(function (error) {
        return Promise.reject(error)
      })
  },
}
