export default {
  'list-title-drone': 'Drone pilot list',
  'create-title-drone': 'Drone pilot create',
  'updata-title-drone': 'Drone pilot edit',
  'create-button': 'Create',
  'updata-button': 'Edit',
  'delete-button': 'Delete',
  'cancel-button': 'Cancel',
  'detail': {
    'first-name': 'First name',
    'last-name': 'Last name',
    'middle-name': 'Middle name',
  },
  'user-info': {
    'name': 'Name',
    'email': 'Mail',
    'multi-factor-auth': 'Multi factor auth',
    'multi-factor-auth-true': 'validity',
    'multi-factor-auth-false': 'disabled',
    'created': 'Create date',
    'activate-status': 'Activate status',
  },
  'error-message': {
    'enter-first-name': 'Please enter your first name',
    'incorrect-first-name': 'Incorrect first name entered',
    'enter-last-name': 'Please enter your last name',
    'incorrect-last-name': 'Incorrect last name entered',
    'enter-email': 'Please enter a valid email address',
    'email-in-use': 'This email address is already in use',
    'select-mfa': 'Please select multi-factor authentication',
    'create-update-failed': 'Create/update failed',
    'auth-failed': 'Authentication failed.',
    'not-access': 'The user account in use does not have access privileges.',
    'invalid-data': 'Failed to retrieve due to invalid data.',
    'get-failed': 'Failed to get data.',
  },
  'select-users': {
    'company': 'Company',
    'company-admin': 'Company Administrator',
    'select-company': 'Please select a %{subject}',
  },
}
