import AuthInfo from "@/models/liveview-auth";
import LiveviewInfo from "@/models/liveview-info";
import repository from "@/repositories/repository";
import { getUserInfo } from "./browser-data/repository";

const basePath = () => {
  return `/api/v1/tenants/${getUserInfo().tenantId}/companies/${getUserInfo().companyId}/missions`;
};

const toModelAuthInfo = ({
  access_key_id,
  secret_access_key,
  session_token,
}) => {
  return new AuthInfo({
    AccessKeyId: access_key_id,
    SecretAccessKey: secret_access_key,
    SessionToken: session_token,
  });
};

const toModelDeviceInfo = ({
  tenant_id,
  company_id,
  user_id,
  device_uuid,
  model_name,
  registration_date,
  device_nickname,
  region,
}) => {
  return new LiveviewInfo({
    TenantId: tenant_id,
    CompanyId: company_id,
    UserId: user_id,
    DeviceUuid: device_uuid,
    ModelName: model_name,
    RegistrationDate: registration_date,
    DeviceNickname: device_nickname,
    Region: region,
  });
};

export default {
  getAuthorize(deviceId, callback) {
    const params = {
      'deviceid': deviceId,
    }

    return repository
      .get('/api/v1/authorize', {params})
      .then(function (response) {
        callback(toModelAuthInfo(response.data.body));
        return response;
      })
      .catch(function (error) {
        return error;
      })
      .finally(function () {});
  },
  getDeviceInfo(missionId, callback) {
    repository
      .get(`${basePath()}/${missionId}/device`)
      .then(function (response) {
        let responseDataBody = response.data.body

        // レスポンスがnullの場合は空として扱う（ミッションとデバイスが紐づいていない場合を想定）
        if (!responseDataBody) {
          responseDataBody = {}
        }

        callback(toModelDeviceInfo(responseDataBody));
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {});
  },
};
