import camera from './en/camera'
import companies from './en/companies'
import contents from './en/contents'
import menu from './en/menu'
import mission from './en/mission'
import users from './en/users'
import device from './en/device'
import user from './en/user'
import common from './en/common'
import exif from './en/exif'

export default {
  message: {
    camera,
    companies,
    contents,
    device,
    menu,
    mission,
    users,
    user,
    common,
    exif,
  },
}
