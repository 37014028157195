export default class CompanyAdminInfo {
  constructor(
    companyId
    , activateStatus
    , created
    , firstName
    , lastName
    , middleName
    , email
    , multiFactorAuth
    , role
  ) {
    this.companyId = companyId
    this.activateStatus = activateStatus
    this.created = created
    this.firstName = firstName
    this.lastName = lastName
    this.middleName = middleName
    this.email = email
    this.multiFactorAuth = multiFactorAuth
    this.role = role
  }

  fullName() {
    return (this.firstName + ' ' + this.middleName + ' ' + this.lastName).replace('  ', ' ')
  }
}
